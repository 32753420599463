<template>
  <v-container fluid fill-height class="bg-texture">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-form ref="form" v-model="valid" @submit="login">
          <v-card
            elevation="12"
            :loading="loading_status"
            loader-height="10"
            class="mx-auto bg-login"
            max-width="344"
            outlined
          >
            <v-card-title class="d-flex justify-center m-2">
              <h1 class="diaplay-4">Factura Electrónica</h1>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="credential.store"
                label="Tienda"
                :rules="f_required"
                id="store"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="credential.user_email"
                :rules="f_required"
                label="Usuario"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="credential.user_password"
                label="Clave"
                type="password"
                :rules="f_required"
                outlined
                required
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  type="submit"
                  class="mr-4"
                  @click="validate"
                >
                  Ingresar
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";
export default {
  name: "Login",

  props: {
    nextUrl: {
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: null,
      },
      profile: createProfile(),
    };
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    document.getElementById("store").focus();
  },
  methods: {
    getLogo: function () {
      var images = require.context("../assets/", false, /\.jpg$/);
      return images("./logo.jpg");
    },
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      this.loading_status = true;
      var md5 = require("md5");
      this.credential.user_password = md5(this.credential.user_password);
      console.log(this.credential);
      webserver("login", this.credential, (data) => {
        this.loading_status = false;
        this.profile.name = data.profile.user_name;
        this.profile.email = data.profile.user_email;
        this.profile.token = data.profile.token;
        this.profile.picture = data.profile.user_photo;
        this.profile.socialName = "SW67";
        this.profile.user_access = data.profile.user_access;

        this.$store.dispatch("setProfile", this.profile);
        this.$store.dispatch("setSeller", data.store);
        window.token = data.token;
        //master = data.master;
        window.profile = data.profile;
        window.curvas = data.curvas;
        window.store = data.store;
        window.seller = data.seller;
        window.settings = data.settings.reduce(function (r, a) {
          r[a.tabla] = r[a.tabla] || [];
          r[a.tabla].push(a);
          return r;
        }, Object.create(null));

        this.$store.dispatch("setNavStatus", true);
        this.$store.dispatch("set_menu", true);

        if (this.nextUrl) {
          this.$router.push(this.nextUrl);
        } else {
          this.$router.push("/Home");
        }
      });
    },
  },
};
//background: url("bg-welcome.jpg") no-repeat center center fixed;
</script>

<style scoped>
.bg-texture {
  background: url("../assets/login.jpg") no-repeat center center fixed;
  /* background: #cfd8dc; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.bg-login {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}
</style>
